.split-screen {
  display: flex;
  height: 100vh;
}

.left-pane {
  width: 30%; /* Adjust the width as needed */
  background: #fff; /* Your left pane background color */
  display: flex;
  align-items: flex-start; /* Aligns the content to the top */
  justify-content: center; /* Centers the content horizontally */
  padding: 1rem;
}

.content {
  max-width: 450px;
  text-align: left; /* Ensures text within .content is aligned to the left */
  margin-top: 50px; /* Adds 50px margin to the top */
  display: flex;
  flex-direction: column;
  /* Keep existing padding and other styles */
  padding: 1rem;
}

.login-instruction {
  font: normal normal 300 25px/29px HelveticaNeue;
  letter-spacing: 0px;
  color: #6b6a6a;
  text-align: left; /* Align the text to the left */
}

.right-pane {
  width: 70%; /* Adjust the width as needed */
  background-image: url("../../vendor/AdobeStock_230238231@2x.png"); /* Adjust the path as needed */
  background-size: cover;
  background-position: center;
}

.main-text-container {
  text-align: left;
}

.login-logo {
  width: 230px; /* Increase the width as needed */
  display: block;
  margin: 1rem auto 5rem auto; /* Center the logo and keep a margin at the bottom */
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  margin-top: 50px;
}

.login-button {
  background: linear-gradient(to right, #943cfb, #be65ec);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 41px !important; /* Rounded corners (half of the height) */
  width: 185px;
  height: 50px;
  cursor: pointer;
  font-size: 20px !important; /* Font size set to 20px */
  font-family: "Century Gothic", sans-serif !important; /* Use "Century Gothic" font */
  font-weight: bold !important; /* Set the font weight to bold */
  /* Add any other styles you need for your button */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .split-screen {
    flex-direction: column;
    height: auto;
  }

  .left-pane {
    width: 100%; /* Full width on mobile */
    padding: 1rem; /* Adjust padding for smaller screens */
  }

  .right-pane {
    display: none; /* Hide the right pane on mobile */
  }
}

h1 {
  font-size: 1.5rem; /* Adjust as needed */
  color: #00468b; /* Adjust to match the color in your image */
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
  color: #000; /* Adjust as needed */
}

.login-button {
  background-color: #0057b7; /* Adjust to match the color in your image */
  color: white;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
.forgot-password {
  margin-top: 1rem;
}
.forgot-password,
.extra-link {
  display: block;
  color: #2b5baa; /* Adjust to match the color in your image */
  margin-bottom: 0.5rem;
  text-decoration: none;
  font-size: 20px;
}

.extra-link-container {
  margin-top: 50px;
}

/* Ensure the rest of your styles prevent overflow */
body,
html {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

* {
  box-sizing: border-box;
}
