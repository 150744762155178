@font-face {
  font-family: "Helvetica Neue";
  src: url("../vendor/fonts/HelveticaNeue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue Light";
  src: url("../vendor/fonts/HelveticaNeue\ Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
